
import {Vue, Component, Watch} from 'vue-property-decorator';
import goodSaleDialog from "@/views/business/business/goodSaleDialog.vue";
import {Util} from "@/common/util";
import GoodsSellAPIClass from "@/common/api/goodsSell";
import billPrint from "@/components/print.vue";
@Component({
  components:{
    billPrint,
    goodSaleDialog
  }
})
export default class componentsGoodsSale extends Vue {
  private classApi: any = new GoodsSellAPIClass();
  private onShowGoodsSale(): void {
    this.$refs.goodSale.show('','')
  }
  private orderinfo: any = {};
  private selectgoodsList: any = [];
  private goodsprice: number = 0;
  private pay: boolean = false;
  private pay_type: any = [
    // {
    //   check: 4,
    //   name: "会员",
    //   icon: require("@/assets/dialog/huiyuan.png"),
    // },
    {
      check: 2,
      name: "微信或支付宝",
      icon: require("@/assets/dialog/weixin.png"),
    },
    {
      check: 6,
      name: "现金",
      icon: require("@/assets/dialog/xianjin.png"),
    },
    {
      check: 3,
      name: "线下收款码",
      icon: require("@/assets/dialog/shoukuanma.png"),
    },
    // {
    //   check: 1,
    //   name: "微信",
    //   icon: require("@/assets/dialog/icon／微信@2x.png"),
    // },
  ];
  private check: number = 2;
  private userinfo: any = {
    type: 0,
  };
  private msg: string = "";
  private phone: string = "";
  private isLoading:boolean = false
  private isPayLoading:boolean = false
  private scanCode: boolean = false;
  private getgoodsprice(): void {
    this.goodsprice = 0;
    for (let i of this.selectgoodsList) {
      this.goodsprice += i.num * i.price;
    }
  }
  private biz_type: number = 2;
  private code: string = "";
  private orderpay(): void {
    // //1商品预支付 2商品扫码收款
    if(this.check === 2){
      this.biz_type = 2
      this.scanCodePay();
      return;
    }
    if(this.check === 3  || this.check === 6){
      this.biz_type = 1
    }
    //现金支付或会员支付
    if (this.check === 3 || this.check === 6) {
      if(this.isPayLoading){
        return;
      }
      this.isPayLoading = true
      this.classApi.payrequest(
          {
            order_id: this.orderinfo.id,
            pay_type: this.check,
            biz_type: this.biz_type,
            user_id: this.userinfo.id,
            // code: this.code,
            // is_vip_pay: this.is_vip_pay,
          },
          () => {
            Util.showToast("支付成功");
            this.code = "";
            this.userinfo.id = "";
            this.msg = "";
            this.phone = "";
            this.pay = false;
            this.scanCode = false;
            setTimeout(()=>{
              this.isPayLoading = false
            },300)
          },()=>{
            setTimeout(()=>{
              this.isPayLoading = false
            },300)
          }
      );
      return
    }
  }
  private goodsSuccess(obj:any):void{
    this.selectgoodsList = obj.selectgoodsList
    this.orderinfo = obj.orderinfo;
    this.getgoodsprice();
    this.pay = true;
    this.userinfo.id = "";
    this.msg = "";
    this.phone = "";
    this.isLoading = false
  }

  private payquery(): void {
    this.classApi.payquery(
        {
          order_id: this.orderinfo.id,
          biz_type: 2,
        },
        (res: any) => {
          if (this.buy_ok.includes(res.code)) {
            Util.showToast("支付成功");
            this.code = "";
            this.pay = false;
            this.scanCode = false;
            clearInterval(this.timer);
          }
        },
        (res: any) => {
          // if (this.buy_two.includes(res.code)) {
          //   if (res.code == 400217) {
          //     Util.showToast("需要用户输入支付密码");
          //   } else if (res.code == 400218) {
          //     Util.showToast("付款码错误，请刷新二维码重试");
          //   } else if (res.code == 400214) {
          //     Util.showToast("支付失败，余额不足");
          //   }
          // } else if (this.buy_no.includes(res.code)) {
          //   Util.showToast("支付失败");
          // }
        }
    );
  }
  private changePayType(check: number): void {
    this.check = check;
    if (check == 1 || check == 2) {
      this.scanCodePay();
      console.log('check,biz_type',this.check,this.biz_type)
    }
  }
  private scanCodePay(): void {
    this.scanCode = true;
    this.getscanCode(2);
  }
  private auth_code: string = "";
  private buy_ok = [200, 400220, 400213];
  private buy_two = [400217, 400218, 400214];
  private timer: any = null;
  private goodcode: string = "";
  private payrequest(): void {
    this.classApi.payrequest(
        {
          order_id: this.orderinfo.id,
          pay_type: this.check,
          biz_type: this.biz_type,
          auth_code: this.auth_code,
          user_id: this.userinfo.id,
          // code: this.code,
          // is_vip_pay: this.is_vip_pay,
        },
        (res: any) => {
          if (this.buy_ok.includes(res.code)) {
            Util.showToast("支付成功");
            // this.renewal = false;
            // this.show_table = false;
            this.check = 4;
            this.code = "";
            (this.auth_code = ""), (this.pay = false);
            this.scanCode = false;
          } else if (this.buy_two.includes(res.code)) {
            if (res.code == 400217) {
              Util.showToast("需要用户输入支付密码");
              this.timer = window.setInterval(() => {
                this.payquery();
              }, 5000);
            } else if (res.code == 400218) {
              Util.showToast("付款码错误，请刷新二维码重试");
              this.timer = setInterval(() => {
                this.payquery();
              }, 5000);
            } else if (res.code == 400214) {
              Util.showToast("支付失败，余额不足");
              this.timer = setInterval(() => {
                this.payquery();
              }, 5000);
            }
          }
          // else {
          //   this.scanCode = false;
          //   // this.pay=false
          //   this.buygoods();
          // }
        },
        () => {
          if (this.timer == null) {
            this.timer = setInterval(() => {
              this.payquery();
            }, 5000);
          }
        }
    );
  }

  private scanCodeadd(): void {
    this.classApi.goodscan(
        {
          bar_code: this.goodcode,
        },
        (res: any) => {
          this.selectgood(res.data.item);
        }
    );
  }
  private selectgoods: any = new Set();
  private selectgood(row: any): void {
    row.num = 1;
    if (this.selectgoods.size > 0) {
      this.selectgoods.forEach((item: any) => {
        if (item.id != row.id) {
          this.selectgoods.add(row);
        }
      });
    } else {
      this.selectgoods.add(row);
    }

    // console.log(this.selectgoods.has(row))
    // if (this.selectgoods.has(row)) {
    //   console.log(this.selecgoodspricetgoods);
    // } else {
    //   this.selectgoods.add(row);
    //   console.log(this.selectgoods);
    // }
    this.selectgoodsList = [...this.selectgoods];
    this.getgoodsprice();
  }

  private getscanCode(type: number): void {
    let that = this;
    let str = "";
    document.onkeydown = async function (e: any) {
      console.log('999999999999999999999999999')
      if (e.target.tagName !== "BODY") {
        str = "";
      } else {
        if (e.keyCode !== 13) {
          if (e.keyCode >= 32 && e.keyCode <= 126) {
            let k = e.key;
            str += k;
          }
        } else {
          if (str) {
            if (type == 1) {
              that.goodcode = str;
              str = "";
              that.scanCodeadd();
            } else {
              if (that.auth_code != "") {
                let res = await that.buygoods();
                if (res == 0) {
                  that.auth_code = str;
                  str = "";
                  that.payrequest();
                }
              } else {
                that.auth_code = str;
                str = "";
                that.payrequest();
              }
            }
          }
        }
      }
    };
  }

  private async buygoods() {
    if(this.isLoading){
      return;
    }
    this.isLoading = true
    let goods = "";
    for (let i of this.selectgoodsList) {
      goods += i.id + "=" + i.num + ",";
    }
    this.classApi.goodsSale({goods: goods}, (res: any) => {
      this.orderinfo = res.data;
      this.pay = true;
      this.userinfo.id = "";
      this.msg = "";
      this.phone = "";
      this.isLoading = false
      setTimeout(()=>{
        this.isLoading = false
      },300)
    },()=>{
      setTimeout(()=>{
        this.isLoading = false
      },300)
    });
  }

  @Watch("scanCode")
  private changescanCode(): void {
    if (!this.scanCode) {
      window.clearInterval(this.timer);
      this.timer = null;
    }
  }

}
